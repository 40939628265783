<template>
  <v-container class="pa-5 mb-13">
    <v-card v-if="tickets.length" class="pb-10">
      <v-card-title class="pt-5 pl-10">Tickets </v-card-title>
      <v-row class="ml-7" style="width: 96%; height: 700px">
        <v-col cols="3" class="pt-0">
          <v-card
            style="background: #f5f5f5; height: 92%; overflow: auto"
            elevation="0"
          >
            <v-card-actions class="ml-2 mb-2">
              <v-list three-line color="#f5f5f5" width="100%">
                <template v-for="(item, index) in tickets">
                  <v-divider
                    v-if="item.divider"
                    :key="index"
                    :inset="item.inset"
                  ></v-divider>

                  <v-list-item
                    v-else
                    :key="item.sender_name"
                    @click="openchat(item)"
                    round
                  >
                    <v-avatar v-if="item.sender_name" color="indigo">
                      <span class="white--text">{{
                        item.sender_name.slice(0, 2)
                      }}</span>
                    </v-avatar>
                    <v-avatar v-else color="indigo">
                      <span class="white--text">Un</span>
                    </v-avatar>

                    <v-list-item-content class="pl-4 mt-3">
                      <v-list-item-title v-if="item.sender_name">
                        User Name: {{ item.sender_name }}
                      </v-list-item-title>
                      <v-list-item-title v-else>Unknown</v-list-item-title>
                      <v-list-item-subtitle>
                        User ID: {{ item.user_id }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list>
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col cols="9" class="pt-0">
          <v-card style="background: #f5f5f5" elevation="0" min-height="90%">
            <v-row class="pa-5 pb-0">
              <v-col>
                <p class="font-weight-medium">
                  Ticket ID:
                  <span class="font-weight-regular">{{ showItem.id }}</span>
                </p>
                <p class="font-weight-medium">
                  Sender Email:
                  <span class="font-weight-regular">{{
                    showItem.sender_name
                  }}</span>
                </p>
                <p class="font-weight-medium">
                  Date:
                  <span class="font-weight-regular">{{
                    showItem.created
                  }}</span>
                </p>
              </v-col>
              <v-col>
                <div class="">
                  <p class="font-weight-medium">
                    Depertment:
                    <span class="font-weight-regular">
                      {{ showItem.department }}</span
                    >
                  </p>
                  <p class="font-weight-medium">
                    Status:
                    <span class="font-weight-regular">{{
                      showItem.status
                    }}</span>
                  </p>
                </div>
              </v-col>
            </v-row>

            <v-row class="pa-5 ml-0 pt-0 pl-2" justify="end">
              <v-col class="pt-0">
                <p class="font-weight-medium">Complain :</p>
                <p>{{ showItem.complain }}</p>
              </v-col>
            </v-row>

            <v-row class="mx-2">
              <v-card color="#f5f5f5" width="100%" elevation="0" class="mt-0">
                <v-card-title> Replies:</v-card-title>

                <v-card-text
                  style="
                    max-height: 200px;
                    overflow: auto;
                    min-height: 200px;
                    display: flex;
                    flex-direction: column-reverse;
                  "
                  class="pl-0 pb-0"
                >
                  <v-list ref="chat" id="replies" color="#f5f5f5">
                    <v-list-item
                      two-line
                      v-for="(item, index) in showItem.replies"
                      :key="index"
                    >
                      <v-list-item-content
                        :class="
                          item.is_staff ? 'blue lighten-4' : 'teal lighten-4'
                        "
                        style="padding: 10px; border-radius: 25px"
                      >
                        <p
                          :class="
                            item.is_staff
                              ? 'black--text text-start font-weight-medium'
                              : 'black--text text-end font-weight-medium'
                          "
                          style="font-size: 18px"
                          >
                          {{ item.reply }}
                        </p>
                        <v-list-item-subtitle
                          :class="
                            item.is_staff
                              ? 'blue--text text--darken-4 text-start'
                              : 'blue--text text--darken-4 text-end'
                          "
                          >by: {{ item.sender }}</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>

                <v-card-actions>
                  <v-form style="min-width: 100%">
                    <v-textarea
                      rows="1"
                      auto-grow
                      v-model="reply"
                      label="Reply"
                      outlined
                      dense
                    ></v-textarea>

                    <v-btn
                      dark
                      fab
                      small
                      color="primary"
                      class="float-right mb-2"
                      style="margin-top: -15px"
                      @click.prevent="submit"
                    >
                      <v-icon dark>mdi-send</v-icon>
                    </v-btn>
                  </v-form>
                </v-card-actions>
              </v-card>
            </v-row>

            <!-- <v-row class="pa-5">
              <v-card color="#f5f5f5" elevation="0">
                <v-card-text>
                  <v-row class="ml-0">
                    <v-col>
                      <p class="mr-2">Rating: {{ showItem.rating }}</p>
                      <v-rating
                        v-model="showItem.rating"
                        background-color="white"
                        color="yellow accent-4"
                        dense
                        half-increments
                        hover
                        size="18"
                        readonly
                      ></v-rating>
                    </v-col>
                  </v-row>
                  <v-row class="ml-0">
                    <v-col>
                      <p>{{ showItem.content }}</p>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-row> -->
          </v-card>
        </v-col>
      </v-row>
    </v-card>

    <v-card v-else min-height="70vh" class="d-flex align-center justify-center">
      <p class="text-h4">There is no Support tickets to show</p>
    </v-card>

    <!-- <v-card>
      <v-row class="ml-7" style="width: 96% ;max-height: 600px">
        <v-col cols="3">
          <v-card
            style="background: #f5f5f5; height: 100%; overflow: auto"
            class="pb-1"
          >
            <v-card-title style="font-size: 20px" class="pt-5">
              Support Ticket
              <v-spacer></v-spacer>
            </v-card-title>

            <v-card-actions class="ml-2 mb-2">
              <v-list three-line color="#f5f5f5" width="100%" round>
                <template v-for="(item, index) in tickets">
                  <v-subheader
                    v-if="item.header"
                    :key="item.header"
                    v-text="item.header"
                  ></v-subheader>

                  <v-divider
                    v-else-if="item.divider"
                    :key="index"
                    :inset="item.inset"
                  ></v-divider>

                  <v-list-item
                    v-else
                    :key="item.sender_name"
                    @click="openchat(item)"
                    round
                  >
                    <v-list-item-avatar v-if="item.sender_picture != ' ' ">
                      <v-img  :src="item.sender_picture"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-avatar v-else>
                      <v-icon dark large color="indigo">mdi-account-circle</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title
                        v-html="item.sender_name"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-html="item.title"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list>
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col cols="9">
          <v-card
            style="background: #f5f5f5"
            v-if="
              !(
                Object.keys(showItem).length === 0 &&
                showItem.constructor === Object
              )
            "
          >
            <v-row style="min-height: 100%" class="mx-5">
              <v-col
                ><p class="font-weight-medium">
                  Ticket ID:
                  <span class="font-weight-regular">{{ showItem.id }}</span>
                </p>
              </v-col>
              <v-col
                ><p class="font-weight-medium">
                  Sender Email:<span class="font-weight-regular">{{
                    showItem.sender_name
                  }}</span>
                </p>
              </v-col>
              <v-col
                ><p class="font-weight-medium">
                  Depertment:
                  <span class="font-weight-regular">{{
                    showItem.department
                  }}</span>
                </p>
              </v-col>
              <v-col
                ><p class="font-weight-medium">
                  Status:<span class="font-weight-regular">{{
                    showItem.status
                  }}</span>
                </p>
              </v-col>
              <v-col
                ><p class="font-weight-medium">
                  Date:
                  <span class="font-weight-regular">{{
                    showItem.created
                  }}</span>
                </p>
              </v-col>
            </v-row>
            <v-row class="pa-5 ml-0">
              <v-col>
                <p class="font-weight-medium">Complain :</p>
                <p>{{ showItem.complain }}</p>
              </v-col>
            </v-row>

            <v-row class="mx-3" width="1000px">
              <v-card color="#f5f5f5" width="100%" class="mb-8" elevation="0">
                 <v-card-title> Replies:</v-card-title>
                <v-card-text
                  style="max-height: 245px; overflow: auto; min-height: 245px"
                  class="pl-0"
                >
               
                  <v-list ref="chat" id="replies" color="#f5f5f5">
                    <v-list-item
                      two-line
                      v-for="(item, index) in showItem.replies"
                      :key="index"
                    >
                      <v-list-item-content :class="item.is_staff ? 'blue lighten-4' : 'teal lighten-4'" style="padding : 10px ; border-radius: 25px;">
                        <v-list-item-title
                          :class="
                            item.is_staff
                              ? 'black--text text-start font-weight-medium'
                              : 'black--text text-end font-weight-medium'
                          "
                          style="font-size: 18px;"
                          >{{ item.reply }}
                        </v-list-item-title>
                        <v-list-item-subtitle
                          :class="
                            item.is_staff
                              ? 'blue--text text--darken-4 text-start'
                              : 'blue--text text--darken-4 text-end'
                          "
                          >by: {{ item.sender }}</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
                <v-card-actions>
                  <v-form style="min-width: 100%">
                    <v-textarea
                      rows="1"
                      auto-grow
                      v-model="reply"
                      label="Reply"
                      outlined
                      dense
                    ></v-textarea>

                    <v-btn
                      dark
                      fab
                      small
                      color="primary"
                      class="float-right mb-2"
                      style="margin-top: -15px"
                      @click.prevent="submit"
                    >
                      <v-icon dark>mdi-send</v-icon>
                    </v-btn>
                  </v-form>
                </v-card-actions>
              </v-card>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-card> -->
  </v-container>
</template>


<script>
export default {
  data: () => ({
    dialog: false,
    dialog2: false,
    reply: "",
    replies: [],
    reply: null,
    showItem: {},
    user_id: "",
    is_staff: "",
    user: {},

    tickets: [],
    newarry: [],
    spticket: [],

    editedIndex: -1,
    editedItem: {
      reply: "",
    },
    defaultItem: {
      reply: "",
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Ticket" : "Edit Ticket";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  watch: {
    replies() {
      setTimeout(() => {
        this.$refs.chat.$el.scrollTop = this.$refs.chat.$el.scrollHeight;
      }, 0);
    },
  },

  methods: {
    initialize() {
      axios.get("supports/ticket_list/").then((response) => {
        this.tickets = response.data.data;
        if (this.tickets.length > 0) {
          this.showItem = this.tickets[0];
        }
      });
    },

    getreplyes() {
      axios
        .get(`supports/specific_ticket/${this.showItem.id}/`)

        .then((response) => {
          this.replies = response.data.data;
        });
    },

    openchat(item) {
      this.showItem = item;
    },

    submit() {
      axios
        .post(`supports/create_reply/${this.showItem.id}/`, {
          user_id: this.user.user_id,
          reply: this.reply,
          is_staff: "true",
        })
        .then((response) => {
          this.showItem.replies.push({
            reply: this.reply,
            is_staff: true,
            sender: this.user.user_email,
          });
          this.reply = "";
        });
    },
    replyitem(item) {
      this.editedItem = item;
      this.dialog2 = true;
      this.contact = item;
    },

    editItem(item) {
      this.editedItem = item;
      this.dialog = true;
    },

    deleteItem(item) {
      if (confirm("Do you really want to delete?")) {
        axios.post(`supports/delete_ticket/${item.id}/`).then((response) => {
          this.tickets = this.tickets.filter(
            (showItem) => showItem.id !== item.id
          );
        });
      }
    },
    // confirm() {
    //   axios
    //     .post(`supports/create_reply/${this.contact.id}/`, {
    //       reply: this.reply,
    //     })
    //     .then((response) => {
    //       this.dialog2 = false;
    //     });
    // },

    close() {
      this.dialog = false;
      this.dialog2 = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    saveEdit() {
      axios
        .post(`supports/edit_reply/${this.editedItem.id}/`, this.editedItem)
        .then((response) => {
          this.dialog = false;
        });
    },

    getreplycolor(is_staff) {
      if (is_staff == "true") return "red";
      else return "green";
    },
  },
  mounted() {
    this.initialize();
    this.getreplyes();

    this.user = JSON.parse(localStorage.getItem("user"));
  },
};
</script>